import {Box, Stack, Tab, Tabs, Typography} from "@mui/material";
import {Dispatch, SetStateAction, SyntheticEvent, useEffect, useRef, useState} from "react";
import {Channel} from "./Channel";
import {useDataContext} from "../data/Data";
import {useApiInterface} from "../data/useApiInterface";
import {PopoutChannel} from "./PopoutChannel";
import {useWSInterface} from "../data/WSInterface";
import {useSource} from "../data/useSource";
import {slcolor} from "../site/Theme";
import {TDestination} from "../data/datatypes";
import {Management} from "./Management";
import {UserList} from "./UserList";
import {Chat} from "./Chat";
import {CameraController} from "./CameraController";
import {HStack, VStack} from "./SiteTools";

export const MainContent = () => {
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [groupsSelectedDestination, setGroupsSelectedDestination] = useState<{ [groupName: string]: string }>({});
    const {sources, groups, setInControl, channelsPoppedOut, userData} = useDataContext();
    const [selectedDestination, setSelectedDestination] = useState<TDestination | undefined>();
    const [tabs, setTabs] = useState<JSX.Element>();
    const [destinations, setDestinations] = useState<JSX.Element[]>();
    const [channels, setChannels] = useState<JSX.Element>();
    const {logout} = useApiInterface();
    const groupNames = Object.keys(groups ?? []).sort();
    const enableToolsTabs = userData && (userData.externalPublish || userData.recordings || userData.editUsers);
    useEffect(() => {
        if (selectedGroup === undefined) {
            if (groupNames[0]) {
                setSelectedGroup(groupNames[0]);
            } else if (enableToolsTabs) {
                setSelectedGroup("tools");
            }
        }
    }, [sources, groups, channelsPoppedOut]);

    const groupSelected = (e: SyntheticEvent<Element, Event>, nv: any) => {
        setSelectedGroup(nv);
        setInControl(undefined);
    }
    useEffect(() => {
        let sg = selectedGroup ?? "";
        if (!sg) {
            if (groupNames[0]) {
                sg = groupNames[0];
            } else if (enableToolsTabs) {
                sg = "tools";
            } else {
                sg = "logout";
            }
            setSelectedGroup(sg);
        }
        const groupTabs = groupNames.map(v => <Tab key={v} value={v} label={v}/>)
        const t = <Tabs value={sg} onChange={groupSelected}>
            {groupTabs && groupTabs}
            {enableToolsTabs && <Tab value="tools" label="Publishing/Recordings/Users" sx={{ml: "auto"}}/>}
            <Tab value="logout" onClick={() => logout()} label="Logout" sx={{ml: enableToolsTabs ? "" : "auto"}}/>
        </Tabs>;
        setTabs(t);
        if (!sg || sg == "tools" || sg == "logout") {
            setDestinations(undefined);
            setChannels(undefined);
            return;
        }
        let selectedDestination = groupsSelectedDestination[sg];
        if (!selectedDestination) {
            selectedDestination = Object.keys(groups[sg])[0];
            setGroupsSelectedDestination(gd => {
                gd[sg] = selectedDestination;
                return gd;
            });
        }
        setSelectedDestination(groups[sg][selectedDestination]);
        if (Object.entries(groups[sg]).length == 0 || Object.keys(groups[sg])[0] == "") {
            setDestinations(undefined);
        } else {
            const d = Object.entries(groups[sg]).map(([dc, v]) => <DestinationSelectWidget key={dc} destination={v}
                                                                                           selected={selectedDestination == dc} groupsSelectedDestination={groupsSelectedDestination}
                                                                                           setGroupsSelectedDestination={setGroupsSelectedDestination}/>)
            setDestinations(d);
        }
        const sourceNames = groups[sg][selectedDestination].sources;
        const chnls = sourceNames.map(cn => {
            return <Channel channelName={cn} destination={groups[sg][selectedDestination]} key={cn}/>;
        });
        const c = <HStack flexWrap="wrap" gap={1} justifyContent="space-evenly">{chnls}</HStack>;
        setChannels(c);
    }, [groups, selectedGroup, userData, groupsSelectedDestination]);

    return (<>
        <VStack paddingLeft={3.3} paddingRight={3.3} flexGrow={1} gap={2}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                {tabs}
            </Box>
            {selectedDestination?.eStopStatus == "maintenance" &&
                <HStack sx={{background: "#ffd500", color: "darkslategray", padding: 1, marginTop: -1.9}}>EXHIBIT IS UNDER MAINTENANCE. CAMERAS MAY BE INTERMITENT OR DOWN AT ANY TIME.</HStack>}
            {selectedDestination?.eStopStatus == "activated" &&
                <HStack sx={{background: "#ff0000", color: "white", padding: 1, marginTop: -1.9}}>E-STOP HAS BEEN ACTIVATED AT THIS EXHIBIT. CAMERAS MAY NOT CURRENTLY BE AVAILABLE</HStack>}
            <VStack flexGrow={1} gap={2}>
                <HStack flexWrap="wrap" gap={2} justifyContent="flex-start" marginLeft={6}>
                    {destinations}
                </HStack>
                {channels}
                {selectedGroup === "tools" && userData && (userData.externalPublish || userData.recordings || userData.editUsers) && <Management/>}
            </VStack>
            <HStack gap={3} sx={{height: 224}}>
                <UserList/>
                <Chat/>
                <CameraController/>
            </HStack>
        </VStack>
        {channelsPoppedOut.map(v => <PopoutChannel key={v} channelName={v}/>)}
    </>);
}

const DestinationSelectWidget = ({destination, selected, groupsSelectedDestination, setGroupsSelectedDestination}: {
    destination: TDestination,
    selected: boolean,
    groupsSelectedDestination: { [groupName: string]: string },
    setGroupsSelectedDestination: Dispatch<SetStateAction<{ [groupName: string]: string }>>
}) => {
    const {destinationChannel, destinationName, cnx, groupName} = destination;
    const {source} = useSource(cnx.substring(6));
    const imgRef = useRef<HTMLImageElement>(null);
    const {subscribeChannel, unSubscribeChannel} = useWSInterface();
    const channelIsAliased = cnx.substring(0, 6) == "alias:";
    const channelIsDelayedPlayed = cnx.endsWith("delayplay");

    useEffect(() => {
        if (!source) return;
        subscribeChannel(source?.channelId, source?.channelName, source?.mjpegName, imgRef);
        return () => {
            unSubscribeChannel(source.channelId, imgRef);
        }
    }, [cnx, selected]);
    return <Stack direction="row" border={selected ? "2px solid " + slcolor.orange : "1px solid " + slcolor.lightblue} padding={1} gap={2} sx={{
        background: selected ? slcolor.lightblue : "none",
        cursor: selected ? "default" : "pointer",
        "&:hover": {
            borderColor: slcolor.orange,
        }
    }}
                  onClick={() => {
                      const gsd = {...groupsSelectedDestination};
                      gsd[groupName] = destinationChannel;
                      setGroupsSelectedDestination(gsd);
                  }}
    >
        {channelIsAliased && source && <img ref={imgRef} width={88} height={60} alt={destinationName}/>}
        {channelIsAliased && !source && <VStack width={88} height={60} justifyContent="center"><Typography textAlign="center">Channel not viewable</Typography></VStack>}
        {!channelIsAliased && <VStack width={88} height={60} justifyContent="center"><Typography textAlign="center">{channelIsDelayedPlayed ? "Delayed video playing" : "No channel selected"}</Typography></VStack>}
        <VStack gap={0.5}>
            <Typography>{destinationName}</Typography>
            <VStack>
                <Typography fontSize={12}>Selected channel:</Typography>
                <Typography fontSize={12}>{source?.displayName ?? "none"}</Typography>
            </VStack>
        </VStack>
    </Stack>;
}
