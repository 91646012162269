import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, keyframes, Modal, Stack, TextField, Typography, useMediaQuery} from "@mui/material";
import {Lock, LockOpen, OpenInNew} from "@mui/icons-material";
import {useDataContext, useUserData} from "../data/Data";
import {useMessagesContext} from "./Messages";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import {ErrorData} from "../data/APIError";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {slcolor} from "../site/Theme";
import {useWSInterface} from "../data/WSInterface";
import InfoIcon from '@mui/icons-material/Info';
import {useSource} from "../data/useSource";
import {TDestination} from "../data/datatypes";

dayjs.extend(utc);

const imgScaleFactor = [0.91, 1];

const blink = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;


export const Channel = ({channelName, destination}: { channelName: string, destination: TDestination }) => {
    const userData = useUserData();
    const {destinationPermissions, channelGoingLive, setChannelGoingLive} = useDataContext();
    const {sendChatMessage} = useWSInterface();
    const imgRef = useRef<HTMLImageElement>(null);
    const {source, lockSource, goLive, inControl, toggleControl, setPoppedOut} = useSource(channelName, destination);
    const [goingLive, setGoingLive] = useState(false);
    if (!source) {
        return <></>;
    }
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [lockReason, setLockReason] = useState("");
    const [lockExplanationOpen, setLockExplanationOpen] = useState(false);
    const wideView = useMediaQuery('(min-width: 1140px)');
    const fullPreview = useMediaQuery('(min-width: 1360px)');
    const {subscribeChannel, unSubscribeChannel} = useWSInterface();
    useEffect(() => {
        subscribeChannel(source.channelId, source.channelName, source.mjpegName, imgRef);
        return () => unSubscribeChannel(source.channelId, imgRef);
    }, []);
    useEffect(() => {
        if (destination.cnx.substring(6) == channelName) {
            setGoingLive(false);
        }
    }, [destination]);
    useEffect(() => {
        if (channelGoingLive != channelName && goingLive) setGoingLive(false);
    }, [channelGoingLive]);
    const lockUnlockChannel = () => {
        if (!lockSource) return;
        setBusy(true);
        lockSource(!source.lockout, lockReason)
            .then(() => {
                setLockReason("");
                setLockDialogOpen(false);
            })
            .catch((err: ErrorData) => {
                displayErrorMessage(err.message);
            })
            .finally(() => setBusy(false));
    };
    const setGoLive = () => {
        if (!goLive) return;
        setBusy(true);
        setChannelGoingLive(channelName);
        setGoingLive(true);
        sendChatMessage("CAMCMD,golive," + (source?.channelName ?? ''));
        goLive()
            .catch((err: ErrorData) => {
                displayErrorMessage(err.message);
            })
            .finally(() => setBusy(false));
    };
    const popoutChannel = () => {
        if (!setPoppedOut) return;
        setPoppedOut(true);
    };
    return (<>
        <Box>
            <Stack direction="column" width="fit-content" alignItems="flex-start" gap={0.5}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" alignItems="flex-end">
                        <Typography fontSize="12px" lineHeight={1}>{source.displayName}</Typography>
                        {source.lockout ? <Lock sx={{height: 0.7, color: "palegoldenrod"}}/> :
                            <LockOpen sx={{height: 0.7, color: "palegoldenrod"}}/>}
                        {userData.staff &&
                            <Button variant="text" onClick={() => setLockDialogOpen(true)} sx={{
                                padding: 0,
                                textDecoration: "underline",
                                color: "white",
                                fontSize: "12px",
                                lineHeight: 1,
                                minWidth: "auto"
                            }}>{source.lockout ? "Unlock" : "Lock"}</Button>
                        }
                        {source.lockout && <InfoIcon sx={{height: 0.7, color: "lightcyan", cursor: "pointer"}} onClick={() => setLockExplanationOpen(true)}/>}
                    </Stack>
                    <Stack direction="row" gap={1}>
                        {source.eStopStatus == "maintenance" && <Typography sx={{
                            margin: 0,
                            lineHeight: "12px",
                            color: "white",
                            background: "#b9a22e",
                            padding: "0px 8px",
                            paddingTop: "1px",
                            height: "13px",
                            fontWeight: 700,
                            fontSize: "12px",
                            position: "relative",
                            top: "9px",
                        }}
                        >UNDER MAINTENANCE</Typography>}
                        {source.eStopStatus == "activated" && <Typography sx={{
                            margin: 0,
                            lineHeight: "12px",
                            color: "white",
                            background: "#b92e2e",
                            padding: "0px 8px",
                            paddingTop: "1px",
                            height: "13px",
                            fontWeight: 700,
                            fontSize: "12px",
                            position: "relative",
                            top: "9px",
                        }}>ESTOP ACTIVATED</Typography>}
                        {destination && source.channelName === destination.cnx.substring(6) &&
                            <Typography sx={{
                                margin: 0,
                                lineHeight: "12px",
                                color: "white",
                                background: "#e74d15",
                                padding: "0px 8px",
                                paddingTop: "1px",
                                height: "13px",
                                fontWeight: 700,
                                fontSize: "12px",
                                position: "relative",
                                top: "9px",
                            }}>LIVE NOW</Typography>
                        }
                        {destination && goingLive &&
                            <Typography sx={{
                                margin: 0,
                                lineHeight: "12px",
                                color: "white",
                                background: "#e74d15",
                                padding: "0px 8px",
                                paddingTop: "1px",
                                height: "13px",
                                fontWeight: 700,
                                fontSize: "12px",
                                position: "relative",
                                top: "9px",
                                animation: `${blink} 1s linear infinite`,
                            }}>GOING LIVE</Typography>
                        }
                        <OpenInNew sx={{position: "relative", bottom: -6, height: 18, cursor: "pointer"}} onClick={popoutChannel}/>
                    </Stack>
                </Stack>
                <Box sx={{position: "relative"}}>
                    <img ref={imgRef} width={352 * imgScaleFactor[Number(wideView)]} height={240 * imgScaleFactor[Number(wideView)]} alt={channelName}/>
                    <Box sx={{border: inControl?.channelName === channelName ? "2px solid #00ff00" : "none", boxSizing: "border-box", position: "absolute", top: 0, left: 0, width: "100%", height: "calc(100% - 3px)"}} onClick={() => {
                        toggleControl ? toggleControl() : '';
                    }}/>
                </Box>
                <Stack direction="row" justifyContent="space-between" sx={{width: "100%"}}>
                    <Button variant="contained" sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => setPreviewModalOpen(true)}>HD PREVIEW</Button>
                    {destination && destinationPermissions[destination.destinationChannel] && (!source.lockout || userData.staff) &&
                        <Button variant="contained" disabled={source.channelName === destination.cnx.substring(6)} sx={{paddingTop: 0, paddingBottom: 0}} onClick={setGoLive}>GO
                            LIVE</Button>
                    }
                </Stack>
            </Stack>
        </Box>
        <Dialog open={lockDialogOpen} disableEscapeKeyDown>
            <DialogTitle>{source.lockout ? "UNLOCK" : "LOCK"} {source.displayName}</DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={2}>
                    {source.lockout ? <>
                        <DialogContentText fontWeight="bold">Locked
                            by: {source.whoLockedOutName} on {dayjs(source.timeLockedOut).format("dddd, MMM D, YYYY at h:mm A")}</DialogContentText>
                        <DialogContentText fontWeight="bold">Reason: {source.lockoutMessage}</DialogContentText>
                        <Typography lineHeight={1.3}>
                            Are you sure you want to unlock this camera?
                        </Typography>
                    </> : <>
                        <DialogContentText fontWeight="bold">Reason for locking</DialogContentText>
                        <Typography lineHeight={1.3}>
                            Please enter the reason this camera is being locked. The reason will be visible to other
                            users to inform them why the camera is locked.
                        </Typography>
                        <Box>
                            <TextField
                                autoFocus
                                fullWidth
                                id="reason"
                                name="reason"
                                label="Lock Reason"
                                multiline
                                onChange={(e) => setLockReason(e.target.value)}
                            />
                        </Box>
                    </>}
                </Stack>
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between", padding: "0px 25px", paddingTop: 1, paddingBottom: 2}}>
                <Button variant="outlined" color="error" onClick={() => {
                    setLockReason("");
                    setLockDialogOpen(false);
                }}>
                    CANCEL
                </Button>
                <Button variant="contained" onClick={lockUnlockChannel}>
                    {source.lockout ? "UNLOCK" : "LOCK"}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={lockExplanationOpen} disableEscapeKeyDown>
            <DialogTitle>Channel locked</DialogTitle>
            <DialogContent>
                <DialogContentText fontWeight="bold">Locked
                    by: {source.whoLockedOutName} on {dayjs(source.timeLockedOut).format("dddd, MMM D, YYYY [at] h:mm A")}</DialogContentText>
                <DialogContentText fontWeight="bold">Reason: {source.lockoutMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setLockExplanationOpen(false)}>CLOSE</Button>
            </DialogActions>
        </Dialog>
        <Modal
            open={previewModalOpen}
            onClose={() => setPreviewModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: fullPreview ? 1280 : 640,
                height: (fullPreview ? 1280 : 640) * 0.5625,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <HighlightOffIcon sx={{position: "absolute", top: 0, right: 0, color: slcolor.orange, cursor: "pointer"}} onClick={() => setPreviewModalOpen(false)}/>
                <iframe src={window.location.protocol + "//" + channelName + ".player.camzonecdn.com/v1.2/CamzoneStreamPlayer?iframe=yes&amp;ignorepreroll=yes&amp;channel=" + channelName} width="100%" height="100%"
                        style={{border: 0}}></iframe>
            </Box>
        </Modal>


    </>);
}
